<template>
  <div
    @click="$router.push({ name: 'eventReg', params: { id: item.id } })"
    class="relative border block outline-none focus:outline-none p-10 border-eventBorder hover:bg-eventHover cursor-pointer"
    :class="index % 2 == 1 ? 'md:-bottom-12' : ''"
  >
    <span
      :class="$i18n.locale == 'EN' ? '-left-2' : '-right-2'"
      class="absolute -top-3 bg-white text-btn text-3xl"
      >{{ item.event_date }}</span
    >
    <h3
      :class="$i18n.locale == 'EN' ? 'text-left' : 'text-right'"
      class="text-footerBg text-xl mb-3 font-bold"
    >
      {{ $i18n.locale == "EN" ? item.title_en : item.title_ar }}
    </h3>
    <p
      id="comingEventsP"
      :class="$i18n.locale == 'EN' ? 'text-left' : 'text-right'"
      class="text-newsDescription font-medium text-lg my-3"
    >
      {{ $i18n.locale == "EN" ? item.description_en : item.description_ar }}
    </p>
    <router-link
      :to="{ name: 'eventReg', params: { id: item.id } }"
      class="text-btn text-lg font-bold flex items-center w-full justify-end"
    >
      <span class="mx-3 transform rotate-90">|</span>
      <span> {{ $t("events.seeDetails") }}</span>
    </router-link>
  </div>
</template>
<script>
import lineClamp from "line-clamp";

export default {
  name: "ComingEvents",
  props: ["item", "index"],
  mounted() {
    const title = document.getElementById("comingEventsP");
    lineClamp(title, 3);
  },
};
</script>