<template>
  <div class="border border-searchBorder">
    <img
      v-if="item.photos.length > 0"
      :src="'https://' + item.photos[0].path"
      alt=""
      loading="lazy"
      class="w-full transform scale-105 h-72 object-cover"
    />
    <div
      v-else
      class="
        flex
        items-center
        justify-center
        w-full
        border border-secondry
        transform
        scale-105
        bg-white
        h-72
        
      "
    >
      <img src="../../assets/ImagePlaceholder.svg" alt="" loading="lazy" />
    </div>

    <div class="my-12 text-center">
      <h4 class="text-footerBg text-xl font-semibold my-3">
        {{ $i18n.locale == "EN" ? item.title_en : item.title_ar }}
      </h4>
      <router-link
        :to="{ name: 'eventDetails', params: { id: item.id } }"
        class="
          text-btn
          flex
          items-center
          outline-none
          focus:outline-none
          w-full
          justify-center
        "
      >
        <span class="mx-2">{{ $t("events.showEvent") }}</span>
        <img
          :class="$i18n.locale == 'EN' ? 'transform rotate-180' : ''"
          src="../../assets/VectorBlue.svg"
          alt=""
          loading="lazy"
        />
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  props: ["item"],
  data() {
    return {
      URL: process.env.VUE_APP_URL,
    };
  },
};
</script>