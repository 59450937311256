<template>
  <div id="events">
    <Header
      :title="$t('events.header.title')"
      :description="$t('events.header.description')"
    />
    <div class="bg-white pt-12" v-if="loaded">
      <div class="container mx-auto px-5 lg:px-8">
        <h1
          v-if="upcomingEvents.length > 0"
          class="text-footerBg text-3xl mb-5 mt-20"
        >
          <span> {{ $t("events.comingEvents") }}</span>
          <span class="bg-info w-16 my-2 h-1 rounded-lg block"></span>
        </h1>
        <div
          v-if="upcomingEvents.length > 0"
          class="my-16 grid grid-cols-1 md:grid-cols-2 gap-12 lg:gap-16 mx-auto"
        >
          <ComingEvents
            v-for="(item, index) in upcomingEvents"
            :key="index"
            :item="item"
            :index="index"
          />
        </div>
        <h1
          v-if="outgoingEvents.length > 0"
          class="text-footerBg text-3xl mb-5 mt-20"
        >
          <span> {{ $t("events.endedEvents") }}</span>
          <span class="bg-info w-16 my-2 h-1 rounded-lg block"></span>
        </h1>
        <div
          v-if="outgoingEvents.length > 0"
          class="
            my-16
            grid grid-cols-1
            md:grid-cols-2
            gap-12
            md:gap-20
            lg:gap-24
            mx-auto
          "
        >
          <EndedEvents
            v-for="(item, index) in outgoingEvents"
            :key="index"
            :item="item"
            :index="item.id"
          />
        </div>
      </div>
    </div>
    <div v-else class="flex justify-center py-5">
      <i class="text-4xl mx-auto text-primary bx bx-loader-circle bx-spin"></i>
    </div>
  </div>
</template>
<script>
import Header from "@/components/layout/Header";
import ComingEvents from "@/components/utils/ComingEvents";
import EndedEvents from "@/components/utils/EndedEvents";

export default {
  name: "Events",
  data() {
    return {
      loaded: false,
    };
  },
  created() {
    this.$store.dispatch("fetchUpcomingEvents").then(() => {
      this.$store.dispatch("fetchOutgoingEvents").then(() => {
        this.loaded = true;
      });
    });
  },
  computed: {
    upcomingEvents() {
      return this.$store.getters.upcomingEvents;
    },
    outgoingEvents() {
      return this.$store.getters.outgoingEvents;
    },
  },
  components: {
    Header,
    ComingEvents,
    EndedEvents,
  },
};
</script>